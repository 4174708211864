:root {
    --header-height: 56px;
    --avh: calc( 100vh - var(--header-height));
    --color-light: rgba(0, 82, 204, 0.1);
    --color-light-grey: rgb(159 174 197 / 15%);
    --color-grey: rgb(159 174 197 / 35%);
    --color-link: rgb(37, 56, 88);
}

* {
    box-sizing: border-box;
}

*, body, input, textarea, select, .mat-menu-item {
    font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

::-webkit-scrollbar {
    width: 8px;
    height: 12px;
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 7px;
}

img {
    max-width: 100%;
}

.mat-form-field-appearance-outline {
    &, .mat-input-element {
        cursor: pointer;
    }
    .mat-form-field-outline {
        border-radius: 6px;
        background: #f1f7fc;
    }
}

.mat-raised-button {
    box-shadow: none;
}

// Material - Menu item
body {
    .mat-menu-item {
        height: auto;
        line-height: inherit;
        padding: 7px 10px;
        font-size: 13px;
    }
    .mat-menu-panel {
        min-height: inherit;
    }
    .mat-menu-content:not(:empty) {
        padding: 0;
    }
    .mat-menu-item .mat-icon {
        margin-right: 0;
        font-size: 18px;
        position: relative;
        top: 3px;
    }
}

// Material - Select
body {
    .mat-select-panel .mat-optgroup-label, .mat-select-panel .mat-option {
        line-height: 2.5em;
        height: 2.5em;
    }
    .mat-select-panel .mat-option, .mat-select-min-line {
        font-size: 13px;
    }
}

// Material - Table
body {
    mat-header-row {
        min-height: 36px;
    }
    .mat-header-cell {
        color: var(--color-link);
        font-weight: 700;
        font-size: 14px;
    }
    mat-header-row {
        border-bottom: 2px solid var(--color-link);
    }
}

// Style overrides
body {
    .dashboard-card {
        box-shadow: none !important;
    }
}

html .page-wrapper {
    margin-top: var(--header-height);
    background: transparent;
}

.center-content {
    padding: 30px;
}

.app-toolbar {
    box-shadow: 0px -1px 8px rgb(0 0 0 / 35%), 0px 1px 0px rgb(0 0 0 / 0%);
    z-index: 10 !important;
}

.top-bg {
    background-color: rgb(221 237 247);
    display: none;
}

.page-header--wrapper {
    display: flex;
    justify-content: space-between;
}

.page-container {
    padding-top: 32px;
}

.page-header--title {
    font-size: 24px;
    margin: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;
}

.page-header--title-desc {
    margin: 0;
    font-weight: 400;
}

.page-header--filter {
    .mat-form-field-appearance-outline {
        .mat-form-field-infix {
            padding: .25em 0 .5em 0;
            height: 45px;
        }
        .mat-select-arrow-wrapper {
            transform: translateY(-12%);
        }
        .mat-form-field-outline {
            background: transparent;
        }
        &:not(.mat-form-field-should-float) {
            .mat-form-field-label-wrapper {
                top: -17px;
                font-size: 13px;
            }
        }
    }
    .mat-raised-button {
        box-shadow: none;
        height: 40px;
        width: 0;
        min-width: 45px;
        margin-top: 4px;
        padding: 0;
        font-size: 0;
    }
    .mat-raised-button {
        margin-left: 0 !important;
    }
    .input-row {
        gap: 12px;
    }
}

.user-name-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 600;
    font-size: 22px;
    background: var(--color-grey);
    &.active {
        background: var(--color-primary);
    }
}

.custom_mat_list {
    .user-name-icon {
        width: 30px;
        height: 27px;
        font-size: 13px;
        margin-top: 10px;
        margin-right: 8px;
    }
}

@media (max-width: 768px) {
    .page-header--wrapper {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    .center-content {
        padding: 15px;
    }
    .page-header--filter .input-row {
        margin-top: 20px;
        display: flex;
        gap: 12px;
        flex-direction: column !important;
    }
    .mat-form-field-appearance-outline .mat-form-field-wrapper {
        padding-bottom: 0;
    }
    .page-container mat-table, .page-container .mat-table-wrapper {
        max-height: 100% !important;
    }
    .page-container mat-row, .page-container mat-header-row, .page-container mat-footer-row {
        display: flex !important;
        align-items: flex-start !important;
        flex-direction: column;
        width: auto;
    }
    .page-container mat-cell:first-of-type, .page-container mat-header-cell:first-of-type, .page-container mat-footer-cell:first-of-type {
        padding-left: 0px;
    }
    .page-container mat-cell, .page-container mat-header-cell, .page-container mat-footer-cell {
        align-items: flex-start;
    }
    .page-container .mat-row .cdk-cell:before {
        margin-right: 5px;
        color: grey;
    }
    $column-name-list:( //
    id: 'Action', //
    username: 'User', //
    user: 'User', //
    name: 'User', //
    type: 'Type', //
    salary: 'Salary', //
    overhead: 'Overhead', //
    hourlyinr: 'Hourly INR', //
    date: 'Date', //
    datefrom: 'Date From', //
    dateto: 'Date To', //
    approveddate: 'Approved Date', //
    reason: 'Reason', //
    status: 'Status', //
    signintime: 'Sign-In', //
    signouttime: 'Sign-Out', //
    tminsformatted: 'Office Hrs', //
    ttaskminsformatted: 'Task Hrs', //
    totalworkhours: 'Total Working Hrs', //
    totaluserworkhours: 'Total User Working Hrs', //
    internalcomments: 'Internal Comments ', //
    description: 'Description ', //
    billing: 'Billing  ', //
    billingtype: 'Billing Type', //
    totalhours: 'Total Hours', //
    time: 'Time', //
    project-name: 'Project', //
    title: 'Title', //
    currency: 'Currency', //
    );
    @each $key, $val in $column-name-list {
        .page-container .mat-row .cdk-column-#{$key}:before {
            content: '#{$val}:';
        }
    }
    .page-container mat-row, .page-container mat-footer-row {
        min-height: 27px;
        padding-top: 20px;
    }
    .page-container mat-header-row {
        display: none !important;
    }
    .page-container {
        padding-top: 20px;
        margin-top: 20px;
        border-top: 1px solid var(--color-grey);
    }
}