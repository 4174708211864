@import '@angular/material/theming';
@include mat-core();
$primary: ( Main:#0052cc, Light:#804b85, Dark: #28002f, contrast: ( Main: #f5f5f5, Light: #000, Dark: #fff));
$accent: ( Main: #3980eb, Light: #ffc453, Dark: #be6500, contrast: ( Main: #fff, Light: #fff, Dark: #fff));
$warn:$mat-red;
$localcast-primary: mat-palette($primary, Main, Light, Dark);
$localcast-accent: mat-palette($accent, Main, Light, Dark);
$custom-theme-warn: mat-palette($warn, A200, A400, A700);
// $localcast-primary: mat-palette($mat-light-blue, 900);
// $localcast-accent: mat-palette($mat-blue, 600); // from https://material.io/design/color/#tools-for-picking-colors
$localcast-app-theme: mat-light-theme($localcast-primary, $localcast-accent);
@include angular-material-theme($localcast-app-theme);
// $custom-typography: mat-typography-config( $font-family: 'Segoe UI');
// @include angular-material-typography($custom-typography);
:root {
    --color-primary: #0052cc;
}

.bg_primary {
    background-color: red;
    background-color: mat-color($localcast-primary);
}

.bg_accent {
    background-color: mat-color($localcast-accent);
}

.color_primary {
    color: mat-color($localcast-primary);
}

.color_accent {
    color: mat-color($localcast-accent);
}

.pull-right {
    float: right
}

.pull-left {
    float: left
}

.custom_mat_list .mat-list-item {
    height: 36px !important;
}

.mat-drawer-container {
    background: #ecf3f7;
}

Body {
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

html .page-wrapper {
    height: calc(100vh - 64px);
}

.img-full {
    width: 100%;
}

.hide-pc {
    display: none;
}

.top-bg {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    background-color: #cadce7;
    height: 200px;
}

.center-content {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    position: relative;
    z-index: 2;
    padding: 0 32px;
}

.header-content {
    padding-top: 45px;
    padding-left: 5px;
}

@media(max-width:960px) {
    .top-bg {
        background-color: transparent !important;
    }
}

@media(max-width:600px) {
    .hide-xs {
        display: none !important;
    }
    .hide-pc {
        display: block !important;
    }
    .center-content {
        padding: 0 15px;
    }
    .header-content {
        padding-top: 15px;
    }
}